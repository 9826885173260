import { faMapLocation } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Autocomplete, TextField, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import usePlacesAutoComplete, { getDetails, getGeocode } from "use-places-autocomplete";
import messages from "./messages";
import theme from "@/theme/theme";

type location = {
  zipCode: number | string | undefined;
  city: string;
  country: string;
  countryCode: string;
  departement: string | undefined;
  address: string;
  coordinates: {
    lat: number | undefined;
    lng: number | undefined;
  };
};

const Picker = ({
  onChange,
  label,
  placeholder,
  initialValue,
  disableHelperTxt,
  errorText,
  inputName,
  forceClearValue,
}: {
  onChange: ({ zipCode, city, country, address }: location) => void;
  label?: string;
  placeholder?: string;
  initialValue?: string;
  disableHelperTxt?: boolean;
  errorText?: string;
  inputName?: string;
  forceClearValue?: string;
}) => {
  const { formatMessage: __ } = useIntl();
  const {
    ready,
    suggestions: { data, status: suggestionsStatus },
    setValue,
    value,
  } = usePlacesAutoComplete({
    // HERE GOES QUERY OPTIONS LIKE LANGUAGE AND AREA RESTRICTION
    requestOptions: {
      language: "en",
    },
  });

  const processAddressComponent = (item: any, _location: location) => {
    if (item.types.includes("country")) {
      _location.country = item.long_name;
      _location.countryCode = item.short_name;
    }
    if (item.types.includes("locality")) {
      _location.city = item.short_name;
    }
    if (item.types.includes("postal_code")) {
      _location.zipCode = item.short_name;
    }
    if (item.types.includes("administrative_area_level_2")) {
      _location.departement = item.short_name;
    }
  };

  const fetchPlaceDetails = async (address: string) => {
    if (data.length) {
      const _location: location = {
        zipCode: undefined,
        departement: undefined,
        address: "",
        city: "",
        country: "",
        countryCode: "",
        coordinates: {
          lat: undefined,
          lng: undefined,
        },
      };
      const _place = data.filter(_place => _place.description === address)[0];
      const _details: any = await getDetails({
        placeId: _place.place_id,
        fields: ["address_components"],
      });
      _location.address = address;
      _details.address_components.map((item: any) => processAddressComponent(item, _location));
      const _geoCodingResult = await getGeocode({
        placeId: _place.place_id,
      });
      onChange({
        ..._location,
        coordinates: {
          lat: _geoCodingResult[0]?.geometry?.location?.lat(),
          lng: _geoCodingResult[0]?.geometry?.location?.lng(),
        },
      });
    }
  };

  return (
    <>
      {!value && !disableHelperTxt && (
        <Typography
          fontWeight={200}
          variant="subtitle1"
          color={theme.palette.grey[700]}
          sx={{ mb: 1 }}
        >
          {__(messages.helperTitle)}
        </Typography>
      )}

      <Autocomplete
        key={forceClearValue}
        disabled={!ready}
        filterOptions={x => x}
        size="small"
        defaultValue={initialValue}
        freeSolo
        disableClearable
        onChange={async (_e, newAddress) => await fetchPlaceDetails(newAddress)}
        options={suggestionsStatus === "OK" ? data.map(({ description }) => description) : []}
        clearIcon={null}
        renderInput={params => (
          <TextField
            helperText={errorText}
            placeholder={placeholder ? placeholder : label ? `${label?.toLowerCase()}` : ""}
            sx={{
              backgroundColor: theme.palette.background.default,
              borderRadius: "4px",
            }}
            name={inputName}
            label={label ?? "Location"}
            {...params}
            value={value}
            onChange={e => {
              setValue(e.target.value);
            }}
            InputLabelProps={{
              shrink: true,
              sx: {
                fontWeight: 600,
              },
            }}
            InputProps={{
              ...params.InputProps,
              type: "search",
              endAdornment: (
                <FontAwesomeIcon icon={faMapLocation} color={theme.palette.grey[300]} />
              ),
            }}
            FormHelperTextProps={{
              sx: {
                fontSize: "12px",
                fontWeight: 400,
                color: theme.palette.error.main,
                marginLeft: 0,
              },
            }}
          />
        )}
        sx={{
          "& .MuiInputBase-input": {
            height: "21px",
          },
        }}
      />
    </>
  );
};

export const LocationPicker = ({
  onChange,
  label,
  initialValue,
  disableHelperTxt,
  errorText,
  inputName,
  forceClearValue,
}: {
  onChange: ({ zipCode, city, country, address, countryCode, departement }: location) => void;
  label?: string;
  initialValue?: string;
  disableHelperTxt?: boolean;
  errorText?: string;
  inputName?: string;
  forceClearValue?: string;
}) => {
  return (
    <Box>
      <Picker
        label={label}
        disableHelperTxt={disableHelperTxt}
        initialValue={initialValue}
        onChange={onChange}
        errorText={errorText}
        inputName={inputName}
        forceClearValue={forceClearValue}
      />
    </Box>
  );
};
