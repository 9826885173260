import { BoxIcon } from "@/pages/layout/style";
import theme from "@/theme/theme";
import { IconDefinition } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, ListItemIcon, styled } from "@mui/material";
import { FC } from "react";
import { NavLink, useLocation } from "react-router-dom";
import ListTextSideBar from "../list-item/ListTextSidebar";

interface IHorizontalListItem {
  title: string;
  link: string;
  matchFullLink?: boolean;
  iconName: string;
  key: number;
  notifCount?: number;
}

const classesListButtonSideBar = {
  isActive: "active",
};

export const NavbarItem = styled(NavLink)(({ theme }) => ({
  [`&`]: {
    textDecoration: "none",
    color: theme.palette.text.secondary,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "7px 10px",
    paddingBottom: "5px",
    position: "relative",
    marginRight: "20px",
    borderRadius: "4px",

    "::before": {
      content: '""',
      position: "absolute",
      bottom: "-8px",
      height: "4px",
      width: "0",
      backgroundColor: theme.palette.primary.main,
      borderRadius: 2,
      transition: "width 0.2s ease", 
    },

    "&:hover::before": {
      width: "80%", 
    },

    [`img`]: {
      filter: "invert(45%) brightness(80%) contrast(100%)",
    },

    "&:hover": {
      ".MuiListItemText-primary": {
        color: `#000`,
        textShadow: "0.7px 0px 0px black",
      },
      img: {
        filter: "none",
      },
    },
  },
  [`&.${classesListButtonSideBar.isActive}`]: {
    color: theme.palette.primary.main,
    borderRadius: 3,
    "&::before": {
      width: "80%", 
    },
  },

  [`&.${classesListButtonSideBar.isActive} .MuiListItemText-primary`]: {
    textShadow: "0.7px 0px 0px black",
  },

  [`&.${classesListButtonSideBar.isActive} img`]: {
    filter: "none",
  },
  letterSpacing: "0.3px",
}));

const HorizontalListItem: FC<IHorizontalListItem> = ({
  title,
  link,
  iconName,
  key,
  matchFullLink,
  notifCount = 0,
}) => {
  const location = useLocation();

  return (
    <NavbarItem
      to={link}
      key={key}
      sx={{
        "&": {
          position: "relative",
        },
        [`&.${classesListButtonSideBar.isActive}`]: {
          color: "#000",
          borderRadius: 2,
        },
      }}
    >
      {(matchFullLink ? location.pathname === link : location.pathname.includes(link)) && (
        <Box
          sx={{
            width: "4px",
            height: "35px",
            position: "absolute",
            top: "9px",
            left: "-2px",
            borderRadius: "4px",
          }}
        />
      )}
      <ListItemIcon sx={{ minWidth: 0, position: "relative" }}>
        {typeof iconName !== "string" ? (
          <BoxIcon sx={{ fontSize: "18px", color: "red" }}>
            <FontAwesomeIcon icon={iconName as unknown as IconDefinition} />
          </BoxIcon>
        ) : (
          <>
            <img height="18px" src={iconName} alt="icon" />
          </>
        )}

        {notifCount > 0 && (
          <Box
            sx={{
              position: "absolute",
              top: "0",
              right: "0",
              backgroundColor: theme.palette.error.main,
              color: theme.palette.background.default,
              borderRadius: "50%",
              fontSize: "0.55rem !important",
              fontWeight: "bold",
              height: "18px",
              width: "18px",
              transform: "translateY(-8px) translateX(8px)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {notifCount > 9 ? "9+" : notifCount}
          </Box>
        )}
      </ListItemIcon>
      <ListTextSideBar sx={{ mt: "8px" }} primary={title} />
    </NavbarItem>
  );
};

export default HorizontalListItem;
