import { ApiEndpointBuilder } from "@/services/api/types";
import { IBusinessMapStatistics } from "@/types/business-map/business-map-statistics";
import { AdvancedFilter } from "@/types/search-request.ts";

const getEnterpriseBusinessMapStatistics = (builder: ApiEndpointBuilder) => {
  return builder.query<IBusinessMapStatistics[], SearchRequest>({
    query: request => ({
      url: "/enterprises/business-map/statistics",
      method: "POST",
      body: request,
    }),
    providesTags: () => ["EnterpriseMapStatistics"],
  });
};
export default getEnterpriseBusinessMapStatistics;

export interface SearchRequest {
  advancedSearch?: {
    fields: string[];
    keyword: string;
  };
  advancedFilter?: AdvancedFilter;
  pageNumber?: number;
  pageSize?: number;
  orderBy?: string[];
  search?: string;
  activityDomainId?: string;
  subActivityDomainIds?: string[];
  expertiseDomainIds?: string[];
  specialitiesIds?: string[];
  countryCode?: string;
  jobTypeIds?: string[];
  scoreThreshold: number;
}
