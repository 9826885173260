import { ApiEndpointBuilder } from "@/services/api/types";
import { Employee } from "@/types/employee";
import { DataResponse } from "@/types/reponse-data";
import { SearchRequest } from "@/types/search-request";

const getPublicEmployeesMap = (builder: ApiEndpointBuilder) => {
  return builder.query<DataResponse<Employee>, SearchRequest & { scoreThreshold: number }>({
    query: request => {
      return {
        url: `/enterpriseemployees/search/map`,
        method: "POST",
        body: request,
      };
    },

    providesTags: result =>
      result
        ? [
            ...(result?.data ?? []).map((item: Employee) => ({
              type: "publicEmployeeMap",
              id: item.id,
            })),
            { type: "publicEmployeeMap", id: "LIST" },
          ]
        : [{ type: "publicEmployeeMap", id: "LIST" }],
  });
};

export default getPublicEmployeesMap;
