import { ApiEndpointBuilder } from "@/services/api/types";
import { DataResponse } from "@/types/reponse-data";
import { AdvancedFilter } from "@/types/search-request";
import { Enterprise } from "@ilan-types/store/profile";

const searchEnterprisesEndpoint = (builder: ApiEndpointBuilder) => {
  return builder.query<DataResponse<Enterprise>, SearchRequest & { scoreThreshold?: number }>({
    query: request => ({
      url: "/enterprises/search",
      method: "POST",
      body: request,
    }),
    providesTags: () => ["Enterprise"],
  });
};

export default searchEnterprisesEndpoint;

export interface SearchRequest {
  advancedSearch?: {
    fields: string[];
    keyword: string;
  };
  keyword?: string;
  advancedFilter?: AdvancedFilter;
  pageNumber?: number;
  pageSize?: number;
  orderBy?: string[];
  enterpriseCategoryIds?: string[];
  enterpriseSubActivityIds?: string[];
  EnterpriseActivityZoneIds?: string[];
}
