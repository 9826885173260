import { defineMessages } from "react-intl";

export const scope = "userConnectionModal";

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: "Bienvenue à bord !",
  },
  subTitle: {
    id: `${scope}.subTitle`,
    defaultMessage:
      "Pour optimiser votre expérience sur ila26, nous avons besoin de quelques informations. ",
  },
  countryQuestion: {
    id: `${scope}.countryQuestion`,
    defaultMessage: "Dans quel pays travaillez-vous ?",
  },
  countrySubTitle: {
    id: `${scope}.countrySubTitle`,
    defaultMessage: "Veuillez sélectionner le pays où vous exercez votre activité.",
  },
  enterpriseQuestion: {
    id: `${scope}.enterpriseQuestion`,
    defaultMessage: "Dans quelle entreprise travaillez vous ?",
  },
  enterpriseSubTitle: {
    id: `${scope}.enterpriseSubTitle`,
    defaultMessage: "Sélectionnez l’entreprise dans laquelle vous travaillez.",
  },
  enterpriseNameSubTitle: {
    id: `${scope}.enterpriseNameSubTitle`,
    defaultMessage: "Saissez le nom de l’entreprise dans laquelle vous travaillez.",
  },
  enterpriseNamePlaceHolder: {
    id: `${scope}.enterpriseNamePlaceHolder`,
    defaultMessage: "Nom de l’entreprise",
  },
  activityDomainTitle: {
    id: `${scope}.activityDomainTitle`,
    defaultMessage: "Sélectionnez le domaine d’activité de votre entreprise. ",
  },
  oneItemSelection: {
    id: `${scope}.oneItemSelection`,
    defaultMessage: "Un seul choix possible",
  },
  nextButton: {
    id: `${scope}.nextButton`,
    defaultMessage: "Suivant",
  },
  previousButton: {
    id: `${scope}.previousButton`,
    defaultMessage: "Précédent",
  },
  startButton: {
    id: `${scope}.startButton`,
    defaultMessage: "Je commence",
  },
  servicesTitle: {
    id: `${scope}.servicesTitle`,
    defaultMessage: "Précisez votre activité",
  },
  positionTitle: {
    id: `${scope}.positionTitle`,
    defaultMessage: "Votre fontion actuelle",
  },
  defaultPositionTitle: {
    id: `${scope}.positionTitle`,
    defaultMessage: "Cochez cette case si vous êtes le gérant.",
  },
  skills: {
    id: `${scope}.skills`,
    defaultMessage: "Vos spécialités",
  },
  manyItemsSelection: {
    id: `${scope}.manyItemSelection`,
    defaultMessage: "Plusieurs choix possibles",
  },
  phone: {
    id: `${scope}.phone`,
    defaultMessage: "Votre téléphone mobile",
  },
  phoneValidation: {
    id: `${scope}.phoneValidation`,
    defaultMessage: "Valider mon numéro",
  },
  error: {
    id: `${scope}.error`,
    defaultMessage: "Le numéro téléphone n'est pas valide",
  },
  code: {
    id: `${scope}.code`,
    defaultMessage: "Code",
  },
  countryPhoneCode: {
    id: `${scope}.countryPhoneCode`,
    defaultMessage: "Indicatifs téléphoniques internationaux",
  },
  otpNotValidated: {
    id: `${scope}.otpNotValidated`,
    defaultMessage:
      "Votre numéro de téléphone n'est pas valide. Appuyez sur le bouton 'Invalide' pour le révalider.",
  },
  otpValidated: {
    id: `${scope}.otpValidated`,
    defaultMessage: "Votre numéro de téléphone a été validé avec succès.",
  },
  validPhoneNumber: {
    id: `${scope}.validPhoneNumber`,
    defaultMessage: "Votre numéro de téléphone est déja validé.",
  },
  notValidated: {
    id: `${scope}.notValidated`,
    defaultMessage:
      "Le numéro de téléphone doit être validé. Appuyez sur le bouton d'information pour commencer la validation.",
  },
  codeSuccess: {
    id: `${scope}.codeSuccess`,
    defaultMessage: "Le code a été envoyé avec succès, veuillez vérifier votre téléphone.",
  },
  codeError: {
    id: `${scope}.codeError`,
    defaultMessage:
      "Une erreur est survenue lors de la tentative d'envoi d'un code. Veuillez réessayer",
  },
  validOtpPhoneNumber: {
    id: `${scope}.validOtpPhoneNumber`,
    defaultMessage: "Envoyer le code de verification",
  },
  phoneNumber: {
    id: `${scope}.phoneNumber`,
    defaultMessage: "Téléphone",
  },
  requiredField: {
    id: `${scope}.requiredField`,
    defaultMessage: "Ce champ est requis. Veuillez le remplir.",
  },
  updateEmployeeError: {
    id: `${scope}.updateEmployeeError`,
    defaultMessage:
      "Une erreur est survenue lors de l'enregistrement de vos informations. Veuillez réessayer.",
  },
  updateEmployeeSuccess: {
    id: `${scope}.updateEmployeeSuccess`,
    defaultMessage: "Vos informations ont été enregistrées avec succès.",
  },
  selectEnterprisePlaceHolder: {
    id: `${scope}.selectEnterprisePlaceHolder`,
    defaultMessage:
      "Saisissez les premières lettres de votre entreprise pour obtenir des options. ",
  },
  selectPositionPlaceHolder: {
    id: `${scope}.selectPositionPlaceHolder`,
    defaultMessage: "Saisissez ici votre fonction actuelle",
  },
  select: {
    id: `${scope}.select`,
    defaultMessage: "Sélectionner",
  },
  noOptions: {
    id: `${scope}.noOptions`,
    defaultMessage: "Aucune option",
  },
  nextButtonInformation: {
    id: `${scope}.nextButtonInformation`,
    defaultMessage: "Tous les champs avec (*) doivent être remplis pour passer à l’étape suivante",
  },
  otherActivityDomain: {
    id: `${scope}.otherActivityDomain`,
    defaultMessage: "Autres métiers",
  },
  showLess: {
    id: `${scope}.showLess`,
    defaultMessage: "Afficher moins",
  },
  newVersionDescription: {
    id: `${scope}.newVersionDescription`,
    defaultMessage:
      "Une nouvelle version d'ila26 a été mise en ligne, veuillez choisir à nouveau votre métier, vos activités ainsi que vos spécialités pour une meilleure expérience utilisateur !",
  },
});
